import React from 'react';
import ReactPlayer from 'react-player'
import video from '../../../assets/vid/elegance.mp4'
import 'video.js/dist/video-js.css'
import Section from '../../../HOC/Section';
import imgDoron from '../../../assets/img/DORON-e1589177767671-opckwsulu45tgwzq3u7zeocdllnq1nizickpz5p3pc.jpg';

const about = () => {
  return (
    <Section id='about'>
      <div className='container pt-2 pb-5'>
        <div className='section-header pt-5 pb-5 text-center'>
          <h3 className='section-title'>
            <span>אודות </span>
          </h3>

        </div>
        <div className='section-content'>
          <div className='row'>
            <div className='col-md-12 col-lg-6 mb-3'>
              <div className='doronImage'>
                <img src={imgDoron} alt='about company' style={{ "--c": "#668284", "--b": "1px", "--s": "18px" }} />
              </div>
            </div>
            <div className='col-md-12 col-lg-6' style={{ direction: 'rtl', textAlign: 'right' }}>

              <div className='about-description'>
                <p>
                  <h2>דבר המנכ"ל</h2>
                  גולשים יקרים,
                  <br />
                  חברת אלגנס, כשמה כן היא. חברה המעניקה שירות אלגנטי, יוקרתי, איכותי ומקצועי. חברת אלגנס היא שם דבר בענף ניהול המבנים האיכותי ובשנים הקרובות היא תהפוך לחברה המובילה בתחום זה בישראל.
                  <br />
                  אני עוסק בניהול חווית איכות השירות ללקוחות מזה יותר מ-30 שנים והמוטו שלנו כחברת ניהול מובילה הינו לגעת בשחקים כמעוף הציפור ולהמריא אל חוויית שירות מלונאית, ברמה של מלון יוקרה, עבור קהל צרכני ניהול מבנים בכלל ועבור קהל לקוחותינו בפרט.
                  <br />
                  מעבר לפעילותינו השוטפת כחברה עם ערכים של קהילתיות, נהלים מחמירים וברורים, סטנדרטים גבוהים, הופעה ייצוגית ומכובדת סביב השעון, ירידה לפרטים הקטנים, כח אדם איכותי ושירותי, אמצעים טכנולוגיים חכמים ועוד, אני כמנכ"ל החברה מתחייב:
                  <br />
                  • להעניק לקהל לקוחותינו את השירות האיכותי הטוב ביותר בבידול מחברות ניהול אחרות תוך התחשבות מלאה בצרכיהם וברווחתם של דיירינו.
                  <br />
                  • להעניק חוויה של "אווירה מלונאית" – בשירות, בשפה, בריח, בתרבות ובערכים.
                  <br />
                  • ליצירת אווירה של שיתוף קהילתי ומשפחתי בין כלל דיירינו לרבות שימוש בנטוורקינג בין כולם.
                  <br />
                  • לבנייה מתמדת של משאב אנושי איכותי, אמין, מקצועי ואדיב הממוקד במתן שירות לשביעות רצונם המלא של דיירינו.
                  <br />
                  <strong>חברת אלגנס מעניקה חוויית שירות שונה מכל מה שהכרתם, נשמח להעניק אותה גם לכם.</strong>
                  <br />
                  <br /><br />
                  <strong style={{ fontSize: '20px' }}>
                    בברכה,
                    <br />
                    דורון דוד
                    <br />
                    מנכ"ל אלגנס ניהול מגדלים בע"מ
                  </strong>
                </p>

              </div>
            </div>
          </div>
        
        </div>
        <div className='row'>
          <div className='col-md-12 col-lg-12'>
            <br/>
            <ReactPlayer url={video} width="400" playing={true} volume={true} muted={true} loop={true} controls={true} />
          </div>
        </div>
      </div>
    </Section>
  );
};

export default about;

