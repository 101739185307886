import React, { useEffect} from 'react';
import { tokenAPICALL } from "./Services/authService";
import Layout from './components/Layout/Layout';

function App() {
  

  const submitLogin = async (user,pass) => {
    const response = await tokenAPICALL(user, pass);
    if (response) {
      sessionStorage.setItem('token', response.jwtToken);
      sessionStorage.setItem('userData', JSON.stringify(response.userData));
    }
  }
  useEffect(()=>{
    console.log("token: ", sessionStorage.getItem('token'))   
    if (sessionStorage.getItem('token')) {
      console.log("user logged in")
    } else {
      submitLogin('admin','admin123')
    }
  },[])
  
  return <Layout />;
}

export default App;
