import React, { useState, useEffect } from 'react';
import { getSocial } from "../../../Services/AllServices.js";
import Section from '../../../HOC/Section';

import imgOrly from '../../../assets/img/orly.png';
const Social = () => {
  const [social, setSocial] = useState([]);
  const getSocialData = (page, pageSize, searchKey) => {
    getSocial(page, pageSize, searchKey).then((response) => {
      if (response && response.records) {
        setSocial(response.records)
      } else {
        console.log("no Social to show")
      }
    })
  }
  useEffect(() => {
    getSocialData(1, 20, '');
  }, [])

  return (
    <div style={{direction:"rtl", textAlign:"center"}}>
      <a href="https://www.ptnews.co.il/17173" target="_blank">ראיון לחדשות פתח תקווה על חברת אלגנס ניהול מגדלים הפורצת דרך ומביאה עימה בשורה חדשה בניהול ותפעול מגדלי יוקרה</a>
    <Section id='services'>
      
      <div className='container pt-2 pb-5'>
        <div className='section-header pt-5 pb-5 text-center'>
          <h3 className='section-title'>
            <span>קשרי קהילה </span>
          </h3>

        </div>
        <div className='section-content'>

          <div className='row' style={{ direction: 'rtl', textAlign: 'right' }}>

            <div className='col-lg-4 mb-3' style={{ textAlign: 'center' }}>
              <img src={imgOrly} className='card-img-top' alt='Blog 1' style={{ width: "180px" }} /><br></br>
              <span>אורלי אטיאס מנהלת קשרי קהילה</span>
            </div>
            <div className='col-lg-4 mb-3'>
              <ul className="circle-list" style={{ direction: 'rtl', textAlign: 'right' }}>
                <li>דיאלוג/שיתופי פעולה  בן דיירים</li>
                <li>יחסי ציבור- פרסום והסברה על פעילותינו למען הקהילה</li>
                <li>ניהול שוטף של ידע פנים קהילתי</li>
                <li>מפגשים ואירועים חברתיים ומקצועיים</li>
                <li>מינוף רעיונות חדשים  לפיתוח הקהילה </li>
                <li>הנחות והטבות  בעסקים במתחם ומחוצה לו </li>
                <li>קבוצות מיקוד</li>
                <li>סקרי שביעות רצון</li>
              </ul>
            </div>
          </div>

          <div className='row' style={{ direction: 'rtl', textAlign: 'right' }}>
            {social.length === 0 ? (
              <div>No data available.</div>
            ) : (
              social.map((item, index) => (
                <div key={index} className='col-lg-4 mb-3'>
                  <div className='card rounded-0' style={{ minHeight: '615px' }}>
                    <img src={"https://www.elegance.co.il/uploads/" + item.picture} className='card-img-top' alt='Blog 1' />
                    <div className='card-body'>
                      <h5 className='card-title'>{item.title}</h5>
                      <p className='card-text'>
                        {item.description}
                      </p>

                    </div>
                  </div>
                </div>
              ))
            )}

          </div>

        </div>
      </div>
    </Section>
    </div>
  );
};

export default Social;
