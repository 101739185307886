import React from 'react';

import Section from '../../../HOC/Section';
import bgImage from '../../../assets/img/pl-00001.jpg';
//import imgGlobal from '../../../assets/img/DJI_0210.jpeg';
import Link from '../../UI/Link/Link';

const home = () => {
  return (
    <Section id='home'>
      <div>
        <div
          className='home-content p-5'
          style={{ backgroundImage: `url(${bgImage})` }}
        >
          <div className='intro container text-center text-light'>
            <h1 className='title'>ELEGANCE</h1>
            <h2 className='sub-title mb-4'>
            ניהול מבנים שנוגע בשחקים
            </h2>
            <Link target='about' classes='btn btn-dark rounded-0 mr-2'>
              אודותינו
            </Link>
            <Link target='contact' classes='btn btn-light text-dark rounded-0  mr-2'>
              צור קשר
            </Link>
          </div>
          <div className="bird-container bird-container--one">
		<div className="bird bird--one"></div>
	</div>
	
	<div className="bird-container bird-container--two">
		<div className="bird bird--two"></div>
	</div>
	
	<div className="bird-container bird-container--three">
		<div className="bird bird--three"></div>
	</div>
	
	<div className="bird-container bird-container--four">
		<div className="bird bird--four"></div>
	</div>
        </div>
      </div>
    </Section>
  );
};

export default home;
