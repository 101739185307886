import React, { Fragment } from 'react';

import Home from './Home/Home';
import About from './About/About';
import Social from './Social/Social';
import Projects from './Projects/Projects';
import Contact from './Contact/Contact';
import MapMarker from './MapMarker/MapMarker';
import Method from './Method/Method'

const sections = () => {
  return (
    <Fragment>
      <Home />
      <About />
      <Method />
      <Social />
      <Projects />
      {/* <Facts /> */}
      <Contact />
      <MapMarker />
    </Fragment>
  );
};

export default sections;
