import React from 'react';

import Link from '../Link/Link';

const footer = () => {
  return (
    <footer className=''>
      <div className='container text-light pt-5'>
        <div className='row'>
          
          <div className='col-sm-12 col-md-12  mb-5' style={{direction:'rtl',textAlign:'right'}}>
            <div className='footer-title'>
              <h6>יצירת קשר</h6>
            </div>
            <div className='footer-content'>
              <p className='text-muted'>
                <small>אלגנס ניהול מגדלים בע"מ</small>
              </p>
              <p className='text-muted'>
                <small>רח' יצחק רבין 1, פתח תקווה</small>
              </p>
              <p className='text-muted'>
                <small>טלפון: 03-9670700</small>
              </p>
              <p className='text-muted'>
                <small>דוא"ל: info@elegance.co.il</small>
              </p>
              <div className='social-media mt-4'>
                <a href='!#' className='text-light'>
                  <i className='fab fa-facebook-f mr-4' />
                </a>
                
                <a href='https://www.instagram.com/elegance_orly/' className='text-light'>
                  <i className='fab fa-instagram mr-4' />
                </a>
                
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='bottom-footer pt-3 pb-3 text-center'>
        <small>© All Right Reserved. Design By m.eylon studio 2023</small>
      </div>
    </footer>
  );
};

export default footer;
