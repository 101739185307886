import React, {useState, useEffect} from 'react';
import Section from '../../../HOC/Section';

import { getAre_Method } from "../../../Services/AllServices.js";
const Method = () => {
  const [method, setMethod] = useState([]);
  const getMethodsData = (page, pageSize, searchKey) => {
    getAre_Method(page, pageSize, searchKey).then((response) => {
      if (response && response.records) {
        setMethod(response.records);
        console.log("Methods:", response.records)
      } else {
        console.log("no Methods to show")
      }
    })
  }
  useEffect(()=>{
    getMethodsData(1, 20, '');
  },[])
  return (
    <Section id='method'>
      <div className='container pt-2 pb-5'>
        <div className='section-header pt-5 pb-5 text-center'>
          <h3 className='section-title'>
            <span>השיטה שלנו </span>
          </h3>

        </div>
        {method.length === 0 ? (
          <div>No data available.</div>
        ) : (
          
          method.map((item, index) => (
              <div key={index} className={`row ${index % 2 === 0 ? 'even' : 'odd'}`}>
                {index % 2 !== 0 && (
                  <div className='section-content'>
                    <div className='row'>
                      <div className='col-md-12 col-lg-6 mb-3'>
                        <div className='aboutImage'>
                          <img src={"https://www.elegance.co.il/uploads/" + item.section_image} alt='about company' />
                          <div className="content">
                            <h3>{item.header}</h3>
                          </div>
                        </div>
                      </div>
                      <div className='col-md-12 col-lg-6' style={{ direction: 'rtl', textAlign: 'right' }}>
                        <h3 className='about-title'> {item.header} </h3>
                        <div className='about-description'>
                          <p>
                            {item.description}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {index % 2 === 0 && (
                  <div className='section-content'>
                    <div className='row'>

                      <div className='col-md-12 col-lg-6' style={{ direction: 'rtl', textAlign: 'right' }}>
                        <h3 className='about-title'>{item.header}</h3>
                        <div className='about-description'>
                          <p>
                            {item.description}
                          </p>
                        </div>
                      </div>
                      <div className='col-md-12 col-lg-6 mb-3'>
                        <div className='aboutImage'>
                          <img src={"https://www.elegance.co.il/uploads/" + item.section_image} alt='about company' />
                          <div className="content">
                            <h3> {item.header}  </h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ))
          
        )}
      </div>
      
    </Section>
  );
};

export default Method;
