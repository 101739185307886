import React from 'react';
import Section from '../../../HOC/Section';

const mapMarker = () => {
  return (
    <Section id='map'>
      <div>
        <iframe
          title='mapMarker'
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3380.204092187558!2d34.8630202!3d32.0907693!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x151d360204c278c7%3A0xca1f1ecaad1b69b4!2z15PXqNeaINeZ16bXl9enINeo15HXmdefIDEsINek16rXlyDXqten15XXldeU!5e0!3m2!1siw!2sil!4v1673963672243!5m2!1siw!2sil" 
          width='100%'
          height='450'
          frameBorder='0'
          style={{ border: 0, marginBottom: '-7px' }}
          allowFullScreen
        />
      </div>
    </Section>
  );
};

export default mapMarker;

