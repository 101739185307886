import React, { useState, useRef } from 'react';
import emailjs from '@emailjs/browser';
import Section from '../../../HOC/Section';

const Contact = () => {
  const [msgAction, setMsgAction] = useState("")
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault(); // prevents the page from reloading when you hit “Send”
  
    emailjs.sendForm('service_elegance', 'template_xymp56g', form.current, '71Dq48PWFxPYVOGhe')
      .then((result) => {
          // show the user a success message
          console.log(form.current)
          setMsgAction("success message")
      }, (error) => {
          // show the user an error
          setMsgAction("error message")
      });
  };
  
  return (
    <Section id='contact'>
      <div className='container pt-2 pb-5'>
        <div className='section-header pt-5 pb-5 text-center'>
          <h3 className='section-title'>
            <span>טופס יצירת קשר </span>
          </h3>
          <h6 className='section-subtitle mr-auto ml-auto'>
            אנחנו כאן כדי לסייע ללקוחותינו, ללקוחות עתידיים ולדיירי הנכסים בניהולנו סביב השעון. אל תהססו ליצור קשר בכל אחת מדרכי התקשורת המוצעים כאן ונציג מטעמנו ישמח לעמוד לרשותכם בהקדם.
          </h6>
        </div>
        <div className='section-content' style={{ direction: 'rtl' }}>
          <div className='row'>
            <div className='col-md-9 col-lg-7 mr-auto ml-auto'>
            <form ref={form} onSubmit={sendEmail}>
                <div className='form-group'>
                  <input
                    type='text'
                    className='form-control rounded-0'
                    aria-describedby='emailHelp'
                    placeholder=' שם מלא...'
                    name="from_name" 
                  />
                </div>
                <div className='form-group'>
                  <input
                    type='email'
                    className='form-control rounded-0'
                    aria-describedby='emailHelp'
                    placeholder=' דואר אלקטרוני...'
                    name="from_email" 
                  />
                </div>
                <div className='form-group'>
                  <textarea
                    className='form-control rounded-0'
                    rows='5'
                    placeholder='הקלד כאן...'
                    name="message" 
                  />
                </div>
                <div className='form-group text-center'>
                  <input type="submit" className='btn btn-block btn-primary rounded-0 mr-auto ml-auto' style={{ background: '#222' }} value="שלח הודעה"></input>
                </div>
                <label>{msgAction}</label>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
};

export default Contact;
