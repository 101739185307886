import React, { useState, useEffect } from 'react';
import { getProjects } from "../../../Services/AllServices.js";
import Section from '../../../HOC/Section';


const Projects = () => {
  const [project, setProject] = useState([]);
  const getProjectsData = (page, pageSize, searchKey) => {
    getProjects(page, pageSize, searchKey).then((response) => {
      if (response && response.records) {
        console.log("Projects:", response.records)
        setProject(response.records)
      } else {
        console.log("no Projects to show")
      }
    })
  }
  useEffect(() => {
    getProjectsData(1, 20, '');
  }, [])

  return (
    <Section id='projects'>
      <div className='container pt-2 pb-5'>
        <div className='section-header pt-5 pb-5 text-center'>
          <h3 className='section-title'>
            <span>פרויקטים </span>
          </h3>

        </div>
        <div className='section-content'>
          <div className='row' style={{ direction: 'rtl', textAlign: 'right' }}>
            {project.length === 0 ? (
              <div>No data available.</div>
            ) : (
              project.map((item, index) => (


                <div key={index} className='col-lg-4 mb-3'>
                  <div className='card rounded-0'>
                    <img src={"https://www.elegance.co.il/uploads/" + item.proj_image} className='card-img-top' alt='Blog 1' />
                    <div className='card-body' style={{ minHeight: '180px' }}>
                      <h5 className='card-title'>{item.project}</h5>
                      <p className='card-text'>
                        {item.description}
                      </p>
                    </div>
                  </div>
                </div>
              ))
            )} </div>
        </div>
      </div>
    </Section>
  );
};

export default Projects;
