import { APIService } from "./index";

export const getProjects = async (pageNo, pageSize, search) => {
    let res;
    if (search.length === 0) {
        res = await getAllProjects(pageNo, pageSize);
    }


    if (res && res.data) {
        return res.data;
    } else {
        return { records: [], totalCount: 0 }
    }

}

export const getSocial = async (pageNo, pageSize, search) => {
    let res;
    if (search.length === 0) {
        res = await getAllSocial(pageNo, pageSize);
    }

    if (res && res.data) {
        return res.data;
    } else {
        return { records: [], totalCount: 0 }
    }

}

export const getAre_Method = async (pageNo, pageSize, search) => {
    let res;
    if (search.length === 0) {
        res = await getAllAre_Method(pageNo, pageSize);
    }
    
    if (res && res.data) {
        return res.data;
    } else {
        return { records: [], totalCount: 0 }
    }

}

export const getAllProjects = (pageNo, pageSize) => {
    return APIService.api().get(`/Projects/?pageNo=${pageNo}&pageSize=${pageSize}`)
}

export const getAllSocial = (pageNo, pageSize) => {
    return APIService.api().get(`/Social/?pageNo=${pageNo}&pageSize=${pageSize}`)
}

export const getAllAre_Method = (pageNo, pageSize) => {
    return APIService.api().get(`/are_method/?pageNo=${pageNo}&pageSize=${pageSize}`)
}